<template>
    <mega-card class="card-border card-round rounded"
               :class="{'overlay-10 overlay-primary active': isSelected}">

        <div class="card-header" style="background: none;">

            <nav class="nav">

                <router-link class="btn btn-sm p-0 ml-auto"
                             target="_blank"
                             :to="{name: 'Round', params: {round_id: id}}">
                    <i class="i-external-link"></i>
                </router-link>

                <span class="btn btn-sm p-0"
                      @click="$emit('select-force', id)"
                      v-if="selectable"
                      data-click="ignore">
                  <i :class="isSelected ? 'i-check-square' : 'i-square'"></i>
                </span>
            </nav>
        </div>

        <div class="navbar py-3" v-if="!noAuthor">
            <div class="container-fluid">

                <router-link :to="{name: type, params: {id: author.id}}" class="navbar-label w-100 text-dark">

                    <mega-image class="rounded-full navbar-avatar mr-3" small ratio="1x1" style="overflow:hidden;" :src="author.avatar"/>

                    <div class="content">
                        <small class="strong">
                            <i v-if="type === 'Arr'" class="i-globe text-muted text-arround align-middle mr-1"></i>
                            <span>{{author.name}}</span>
                        </small>
                        <small class="text-muted">@{{author.username}}</small>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="media p-relative">

            <div class="card-media card-video"
                 v-if="video_link">

                <mega-image ratio="3x4" :src="preview"/>

                <div class="media-overlay overlay-50 overlay-dark">
                    <div class="text-center">
                        <span class="sticker sticker-lg bg-white"><i class="i-play"></i></span>
                    </div>
                </div>

                <div class="media-overlay overlay-90 overlay-dark media-video"
                     @mouseenter="play"
                     @mouseleave="pause">

                    <router-link class="p-absolute fill-parent z-3" target="_blank" :to="{name: 'Round', params: {round_id: id}}"></router-link>

                    <video ref="video" class="video-player fill-parent" :src="video_link" loop></video>
                </div>
            </div>

            <div class="card-media"
                 v-if="!video_link">

				<div class="media-overlay overlay-50 overlay-dark" v-if="youtube">
                    <div class="text-center">
                        <span class="sticker sticker-lg bg-white"><i class="i-youtube-solid"></i></span>
                    </div>
                </div>

                <router-link class="p-absolute fill-parent z-3" target="_blank" :to="{name: 'Round', params: {round_id: id}}"></router-link>

                <mega-image ratio="3x4" :src="preview"/>
            </div>
        </div>

        <div data-click="ignore"
             @click="onClick">

            <nav class="nav font-small events-disable">
                <span class="btn"><i class="i-thumbs-up"></i> {{likes}}</span>
                <span class="btn"><i class="i-thumbs-down"></i> {{dislikes}}</span>
                <span class="btn ml-auto"><i class="i-message-circle"></i> {{comments}}</span>
            </nav>

            <nav class="nav flex-column font-small">
                <div class="nav-text text-muted">
                    <i class="i-calendar"></i>
                    <span>{{date | u2d("MMMM DD YYYY - LT")}}</span>
                </div>
                <div class="nav-text text-muted">
                    <i class="i-map-pin"></i>
                    <span :class="{'text-danger': !address}">{{address ? address : 'No address'}}</span>
                </div>
            </nav>
        </div>
    </mega-card>
</template>

<script>
    export default {
        props: {
            data: Object,
            noAuthor: Boolean,
            selectable: Boolean
        },
        data() {
            return {
                name: this.data.message,
                id: this.data['idt_round'],
                date: this.data['a_time'],
                preview: this.data['url_preview'],
                likes: this.data.likes['likes_count'],
                dislikes: this.data.likes['dislikes_count'],
                comments: this.data['comments_count'],
                address: this.data['place_address'],
                media: this.data['media'],
				videos: this.data['videos'],
				youtube: this.data['youtube'] || undefined,
                author: {
                    id: this.data.from.id,
                    type: this.data.from.type,
                    name: this.data.from.name,
                    avatar: this.data.from.avatar,
                    username: this.data.from.username || this.data.from.type+'_'+this.data.from.id
                }
            }
		},
        computed: {

            type() {
                let type = undefined;

                if(this.author.type === 'user') type = 'User';
                if(this.author.type === 'arp') type = 'Arr';

                return type;
            },

            video_link() {
                return this.media ? this.media['url'] : (this.videos ? this.videos[0]['url'] : null);
            },

            isSelected() {
                let _selected = false;

                if(this.$parent.selected)
                    _selected = this.$parent.selected.includes(this.id)

                return _selected;
            }
        },
        methods: {

            onClick() {
                if(!this.isSelected){
                    if(window && window.isMobileOrTablet()) this.open();
                    else this.$emit('select', this.id);
                }
                else this.$emit('remove', this.id);
            },

            open() {
                this.$router.push('/round/'+this.id)
            },

            play() {
                if(this.video_link)
                    this.$refs['video'].play();
            },

            pause() {
                if(this.video_link)
                    this.$refs['video'].pause();
            }
        }
    }
</script>
