<template>
  <div class="dashboard-page">

    <mega-header class="overlay-dark overlay-90 text-lighter"
                 title="ARROUND ARR Manager"
                 background="https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260">
      <span class="text-muted">Welcome {{user.email}}!</span>
    </mega-header>

<!--    <div class="navbar sm-down:d-none border-bottom bg-lighter md-up:sticky-top" style="top: 70px;">-->
<!--      <div class="container py-3 sm-down:flex-column">-->

<!--        <router-link :to="{name: 'Round_Post'}" class="btn btn-sm btn-primary md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3">-->
<!--          <span>New Round</span>-->
<!--        </router-link>-->

<!--        <span class="btn btn-sm btn-arround md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3" @click="addModel">-->
<!--          <span>New Model</span>-->
<!--        </span>-->

<!--        <span class="btn btn-sm btn-arround md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3" @click="addGroup">-->
<!--          <span>New Group</span>-->
<!--        </span>-->

<!--        <router-link :to="{name: 'Models', params: { roundType: '3d' }}" class="btn btn-sm btn-light text-left d-block sm-down:w-100 ml-auto">-->
<!--          <i class="i-package float-left"></i>-->
<!--          <span>Models</span>-->
<!--        </router-link>-->

<!--        <router-link :to="{name: 'Rounds'}" class="btn btn-sm btn-light text-left d-block sm-down:w-100">-->
<!--          <i class="i-layers float-left"></i>-->
<!--          <span>Rounds</span>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->

<!--    <section>-->
<!--      <div class="container py-5">-->

<!--        <div class="row">-->

<!--          <div class="md-down:col-12 lg-up:col-6">-->
<!--            <mega-card class="rounded" :loading="count.users === 0">-->

<!--              <div class="card-body">-->
<!--                <h3 class="strong text-primary mb-0" style="line-height: 1">{{count.users}}</h3>-->
<!--                <span class="text-muted">Users</span>-->
<!--              </div>-->

<!--              <div class="card-media rounded-bottom">-->
<!--                <no-ssr>-->
<!--                  <mega-chart ratio="10x2" :labels="Object.keys(dataset.users)" clear :offset="1">-->
<!--                    <mega-chart-set title="New users" :data="Object.values(dataset.users)" color="primary" fill/>-->
<!--                  </mega-chart>-->
<!--                </no-ssr>-->
<!--              </div>-->
<!--            </mega-card>-->
<!--          </div>-->

<!--          <div class="md-down:col-12 lg-up:col-6">-->
<!--            <mega-card class="rounded" :loading="count.rounds === 0">-->

<!--              <div class="card-body">-->
<!--                <h3 class="strong text-success mb-0" style="line-height: 1">{{count.rounds}}</h3>-->
<!--                <span class="text-muted">Rounds</span>-->
<!--              </div>-->

<!--              <div class="card-media rounded-bottom">-->
<!--                <no-ssr>-->
<!--                  <mega-chart ratio="10x2" :labels="Object.keys(dataset.rounds)" clear :offset="20">-->
<!--                    <mega-chart-set title="New rounds" :data="Object.values(dataset.rounds)" color="success" fill/>-->
<!--                  </mega-chart>-->
<!--                </no-ssr>-->
<!--              </div>-->
<!--            </mega-card>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

<!--    <section>-->
<!--      <div class="container pb-5">-->

<!--        <div class="d-flex mb-4">-->
<!--          <h4 class="bold text-muted text-dark mb-0">Last Models</h4>-->
<!--          <router-link class="btn btn-sm ml-auto" to="/models">View All</router-link>-->
<!--        </div>-->

<!--        <no-ssr>-->
<!--          <mega-slider class="row" :options="slider">-->
<!--            <mega-slide v-for="(model, i) in models" :key="i">-->
<!--              <div class="px-3">-->
<!--                <model-card class="cursor-grab" :data="model"/>-->
<!--              </div>-->
<!--            </mega-slide>-->
<!--          </mega-slider>-->
<!--        </no-ssr>-->
<!--      </div>-->
<!--    </section>-->

<!--    <section>-->
<!--      <div class="container pb-5">-->

<!--        <div class="d-flex mb-4">-->
<!--          <h4 class="bold text-muted text-dark mb-0">Last Rounds</h4>-->
<!--          <router-link class="btn btn-sm ml-auto" to="/rounds">View All</router-link>-->
<!--        </div>-->

<!--        <no-ssr>-->
<!--          <mega-slider class="row" :options="slider">-->
<!--              <mega-slide v-for="(round, i) in rounds" :key="i">-->
<!--                  <div class="px-3">-->
<!--                      <round-card class="cursor-grab" :data="round"/>-->
<!--                  </div>-->
<!--              </mega-slide>-->
<!--          </mega-slider>-->
<!--        </no-ssr>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</template>

<script>
  import ModelCard from '../Models/components/model-card'
  import RoundCard from '../Rounds/components/round-card'

  export default {
    name: 'Dashboard',
    data() {
      return {
        count: {
          users: 0,
          models: 0,
          rounds: 0,
          groups: 0
        },
        dataset: {
          users: this.months(),
          models: this.months(),
          rounds: this.months()
        },
        slider: {
          slidesPerView: 4,
          breakpoints: {
            750: {
              slidesPerView: 1
            },
            990: {
              slidesPerView: 2
            },
            1470: {
              slidesPerView: 3
            }
          }
        },
        models: [],
        rounds: [],
        refresh: null,
        year: this.$u2d(new Date(), 'YYYY')
      }
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
    },
    mounted() {
      this.render();
      this.$navbar.name = 'Dashboard';
      this.refresh = setInterval(() => { this.render() }, 5000)
    },
    beforeDestroy() { clearInterval(this.refresh) },
    methods: {

      months() {
        let month = new Date().getMonth();
        let arr = {};
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let months_sorted = [];

        while(months_sorted.length < 6) {

          months_sorted.push(months[month])

          if(month > 0) month--;
          else month = 11;
        }

        months_sorted.reverse().map(m => { arr[m] = 0 })

        return arr;
      },

      render() {
        // this.getUsers();
        // this.getModels();
        // this.getRounds();
        // this.getGroups();
      },

      // getUsers() {
      //
      //   this.$api.v1.get('/userList', {params: {limit: 500, order: 'id_desc'}})
      //       .then(response => {
      //
      //         Object.keys(this.dataset.users).map(k => { this.dataset.users[k] = 0 });
      //
      //         let users = response.data.users;
      //
      //         users.map(user => {
      //
      //           let month = this.$u2d(user['a_time'], 'MMM');
      //           let year = this.$u2d(user['a_time'], 'YYYY');
      //
      //           if(year === this.year)
      //             this.dataset.users[month]++;
      //         });
      //
      //         this.count.users = response.data.count;
      //       });
      // },
      //
      // getModels() {
      //   this.$api.v2.get('/models', {params: {limit: 500}})
      //       .then(response => {
      //
      //         this.models = [];
      //         let models = response.data.models;
      //
      //         let i = 0;
      //         while (i < 4){
      //           if(models[i].modifications){
      //             this.models.push(models[i]);
      //             i++;
      //           }
      //         }
      //
      //         this.count.models = response.data.count;
      //       });
      // },
      //
      // getRounds() {
      //
      //   this.$api.v2.get('/rounds', {params: {limit: 500}})
      //       .then(response => {
      //
      //         let rounds = response.data.rounds;
      //
      //         this.rounds = [];
      //
      //         for (let i = 0; i < 4; i++)
      //           this.rounds.push(rounds[i]);
      //
      //         Object.keys(this.dataset.rounds).map(k => { this.dataset.rounds[k] = 0 });
      //
      //         rounds.map(user => {
      //
      //           let month = this.$u2d(user['a_time'], 'MMM');
      //           let year = this.$u2d(user['a_time'], 'YYYY');
      //
      //           if(year === this.year)
      //             this.dataset.rounds[month]++;
      //         });
      //
      //         this.count.rounds = response.data.count;
      //       });
      // },
      //
      // getGroups() {
      //
      //   this.$api.v2.get('/groups', {params: {limit: 6}})
      //       .then(response => {
      //         this.count.groups = response.data.count;
      //       });
      // }
    },
    components: {
      RoundCard,
      ModelCard
    }
  }
</script>
