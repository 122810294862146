var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c(
        "mega-header",
        {
          staticClass: "overlay-dark overlay-90 text-lighter",
          attrs: {
            title: "ARROUND ARR Manager",
            background:
              "https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          }
        },
        [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v("Welcome " + _vm._s(_vm.user.email) + "!")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }