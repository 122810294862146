var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mega-card",
    {
      staticClass: "card-border card-round rounded",
      class: { "overlay-10 overlay-primary active": _vm.isSelected }
    },
    [
      _c(
        "div",
        { staticClass: "card-header", staticStyle: { background: "none" } },
        [
          _c(
            "nav",
            { staticClass: "nav" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-sm p-0 ml-auto",
                  attrs: {
                    target: "_blank",
                    to: { name: "Round", params: { round_id: _vm.id } }
                  }
                },
                [_c("i", { staticClass: "i-external-link" })]
              ),
              _vm._v(" "),
              _vm.selectable
                ? _c(
                    "span",
                    {
                      staticClass: "btn btn-sm p-0",
                      attrs: { "data-click": "ignore" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("select-force", _vm.id)
                        }
                      }
                    },
                    [
                      _c("i", {
                        class: _vm.isSelected ? "i-check-square" : "i-square"
                      })
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      !_vm.noAuthor
        ? _c("div", { staticClass: "navbar py-3" }, [
            _c(
              "div",
              { staticClass: "container-fluid" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "navbar-label w-100 text-dark",
                    attrs: {
                      to: { name: _vm.type, params: { id: _vm.author.id } }
                    }
                  },
                  [
                    _c("mega-image", {
                      staticClass: "rounded-full navbar-avatar mr-3",
                      staticStyle: { overflow: "hidden" },
                      attrs: { small: "", ratio: "1x1", src: _vm.author.avatar }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _c("small", { staticClass: "strong" }, [
                        _vm.type === "Arr"
                          ? _c("i", {
                              staticClass:
                                "i-globe text-muted text-arround align-middle mr-1"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.author.name))])
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("@" + _vm._s(_vm.author.username))
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "media p-relative" }, [
        _vm.video_link
          ? _c(
              "div",
              { staticClass: "card-media card-video" },
              [
                _c("mega-image", { attrs: { ratio: "3x4", src: _vm.preview } }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "media-overlay overlay-50 overlay-dark" },
                  [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "span",
                        { staticClass: "sticker sticker-lg bg-white" },
                        [_c("i", { staticClass: "i-play" })]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "media-overlay overlay-90 overlay-dark media-video",
                    on: { mouseenter: _vm.play, mouseleave: _vm.pause }
                  },
                  [
                    _c("router-link", {
                      staticClass: "p-absolute fill-parent z-3",
                      attrs: {
                        target: "_blank",
                        to: { name: "Round", params: { round_id: _vm.id } }
                      }
                    }),
                    _vm._v(" "),
                    _c("video", {
                      ref: "video",
                      staticClass: "video-player fill-parent",
                      attrs: { src: _vm.video_link, loop: "" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.video_link
          ? _c(
              "div",
              { staticClass: "card-media" },
              [
                _vm.youtube
                  ? _c(
                      "div",
                      { staticClass: "media-overlay overlay-50 overlay-dark" },
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _c(
                            "span",
                            { staticClass: "sticker sticker-lg bg-white" },
                            [_c("i", { staticClass: "i-youtube-solid" })]
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("router-link", {
                  staticClass: "p-absolute fill-parent z-3",
                  attrs: {
                    target: "_blank",
                    to: { name: "Round", params: { round_id: _vm.id } }
                  }
                }),
                _vm._v(" "),
                _c("mega-image", { attrs: { ratio: "3x4", src: _vm.preview } })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { "data-click": "ignore" }, on: { click: _vm.onClick } },
        [
          _c("nav", { staticClass: "nav font-small events-disable" }, [
            _c("span", { staticClass: "btn" }, [
              _c("i", { staticClass: "i-thumbs-up" }),
              _vm._v(" " + _vm._s(_vm.likes))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "btn" }, [
              _c("i", { staticClass: "i-thumbs-down" }),
              _vm._v(" " + _vm._s(_vm.dislikes))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "btn ml-auto" }, [
              _c("i", { staticClass: "i-message-circle" }),
              _vm._v(" " + _vm._s(_vm.comments))
            ])
          ]),
          _vm._v(" "),
          _c("nav", { staticClass: "nav flex-column font-small" }, [
            _c("div", { staticClass: "nav-text text-muted" }, [
              _c("i", { staticClass: "i-calendar" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm._f("u2d")(_vm.date, "MMMM DD YYYY - LT")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nav-text text-muted" }, [
              _c("i", { staticClass: "i-map-pin" }),
              _vm._v(" "),
              _c("span", { class: { "text-danger": !_vm.address } }, [
                _vm._v(_vm._s(_vm.address ? _vm.address : "No address"))
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }